export const firebaseConfig= {
    "apiKey": "AIzaSyCfMvhWsbEjFx2mKiDtiZEutDlB3ltQs1Y",
    "authDomain": "truenorth-development-166a5.firebaseapp.com",
    "projectId": "truenorth-development-166a5",
    "storageBucket": "truenorth-development-166a5.appspot.com",
    "messagingSenderId": "450607829412",
    "appId": "1:450607829412:web:cd980694783b49ecf67131",
    "measurementId": "G-V6ZTBVTHWK",
    "serviceAccountId": "firebase-adminsdk-b95er@truenorth-development-166a5.iam.gserviceaccount.com",
    "credential": "cert(serviceAccount)",
    "databaseURL": "https://truenorth-development-166a5-default-rtdb.firebaseio.com",
    "hubspotApiBearerToken" : "pat-na1-1fa325c9-57ba-45fb-a6e9-6a2415746fa7",
    "secret" : "Al1YurBas3sali9ui@p"
};